<template>
  <main class="w-full h-custom pt-10 pb-24 flex justify-center">
    <div class="w-1/2 xsm:w-5/6 sm:w-4/3">
      <h1 class="text-3xl font-bold text-center">My Technologies</h1>
      <p class="my-5 text-xl">
        <te-icon name="braces" />
        My programming languages
      </p>
      <section class="grid lg:grid-cols-4 gap-4 md:grid-cols-2 sm:grid-cols-2 xsm:grid-cols-2">
        <card>
          <img class="h-16" src="../assets/html5.png" />
          <span class="text-center">HTML</span>
        </card>
        <card>
          <img class="h-16" src="../assets/css-3.png" />
          <span class="text-center">CSS</span>
        </card>
        <card>
          <img class="h-16" src="../assets/js.png" />
          <span class="text-center">Javascript</span>
        </card>
        <card>
          <img class="h-16" src="../assets/typescript.png" />
          <span class="text-center">Typescript</span>
        </card>
        <card>
          <img class="h-16" src="../assets/dart.png" />
          <span class="text-center">Dart</span>
        </card>
        <card>
          <img class="h-16" src="../assets/python.png" />
          <span class="text-center">Python</span>
        </card>
        <card>
          <img class="h-16" src="../assets/java.png" />
          <span class="text-center">Java</span>
        </card>
        <card>
          <img class="h-16" src="../assets/c-sharp.png" />
          <span class="text-center">C#</span>
        </card>
        <card>
          <img class="h-16" src="../assets/c-.png" />
          <span class="text-center">C++</span>
        </card>
        <card>
          <img class="h-16" src="../assets/php.png" />
          <span class="text-center">PHP</span>
        </card>
      </section>
      <hr class="my-5" />
      <p class="my-5 text-xl">
        <te-icon name="braces" />
        My Frontend Frameworks
      </p>
      <section class="grid lg:grid-cols-4 gap-4 md:grid-cols-2 sm:grid-cols-2 xsm:grid-cols-2">
        <card>
          <img class="h-16" src="../assets/vue-js-icon.png" />
          <span class="text-center">Vue JS (v2 & v3)</span>
        </card>
        <card>
          <img class="h-16" src="../assets/react.png" />
          <span class="text-center">React</span>
        </card>
        <card>
          <img class="h-16" src="../assets/svelte-icon.png" />
          <span class="text-center">Svelte</span>
        </card>
        <card>
          <img class="h-16" src="../assets/alpinejs.png" />
          <span class="text-center">Alpine JS</span>
        </card>
      </section>
      <hr class="my-5" />
      <p class="my-5 text-xl">
        <te-icon name="braces" />
        My Backend Frameworks
      </p>
      <section class="grid lg:grid-cols-4 gap-4 md:grid-cols-2 sm:grid-cols-2 xsm:grid-cols-2">
        <card>
          <img class="h-16" src="../assets/node-js-icon.png" />
          <span class="text-center">Node JS</span>
        </card>
        <card>
          <img class="h-16" src="../assets/deno-icon.png" />
          <span class="text-center">Deno JS</span>
        </card>
        <card>
          <img class="h-16" src="../assets/visual-basic.png" />
          <span class="text-center">.Net Core</span>
        </card>
        <card>
          <img class="h-16" src="../assets/python.png" />
          <span class="text-center">Flask</span>
        </card>
      </section>
      <hr class="my-5" />
      <p class="my-5 text-xl">
        <te-icon name="braces" />
        Mobile Frameworks
      </p>
      <section class="grid lg:grid-cols-4 gap-4 md:grid-cols-2 sm:grid-cols-2 xsm:grid-cols-2">
        <card>
          <img class="h-16" src="../assets/icons8-flutter-64.png" />
          <span class="text-center">Flutter</span>
        </card>
        <card>
          <img class="h-16" src="../assets/react-native.png" />
          <span class="text-center">React Native</span>
        </card>
        <card>
          <img class="h-16" src="../assets/js.png" />
          <span class="text-center">Native Script</span>
        </card>
      </section>
      <hr class="my-5" />
      <p class="my-5 text-xl">
        <te-icon name="braces" />
        Databases
      </p>
      <section class="grid lg:grid-cols-4 gap-4 md:grid-cols-2 sm:grid-cols-2 xsm:grid-cols-2">
        <card>
          <img class="h-16" src="../assets/mysql.png" />
          <span class="text-center">MySQL</span>
        </card>
        <card>
          <img class="h-16" src="../assets/postgresql.png" />
          <span class="text-center">PostgreSQL</span>
        </card>
        <card>
          <img class="h-16" src="../assets/microsoft-sql-server.png" />
          <span class="text-center">MS SQL Server</span>
        </card>
        <card>
          <img class="h-16" src="../assets/database.png" />
          <span class="text-center">SQLite</span>
        </card>
        <card>
          <img class="h-16" src="../assets/mongodb-icon.png" />
          <span class="text-center">MongoDB</span>
        </card>
      </section>
      <hr class="my-5" />
      <p class="my-5 text-xl">
        <te-icon name="braces" />
        Backend as a Service
      </p>
      <section class="grid lg:grid-cols-4 gap-4 md:grid-cols-2 sm:grid-cols-2 xsm:grid-cols-2">
        <card>
          <img class="h-16" src="../assets/firebase.png" />
          <span class="text-center">Firebase</span>
        </card>
        <card>
          <img class="h-16" src="../assets/supabase.png" />
          <span class="text-center">Supabase</span>
        </card>
        <card>
          <img class="h-16" src="../assets/database.png" />
          <span class="text-center">Pocketbase</span>
        </card>
      </section>
      <hr class="my-5" />
      <p class="my-5 text-xl">
        <te-icon name="braces" />
        Cloud Platforms
      </p>
      <section class="grid lg:grid-cols-4 gap-4 md:grid-cols-2 sm:grid-cols-2 xsm:grid-cols-2">
        <card>
          <img class="h-16" src="../assets/aws.png" />
          <span class="text-center">AWS</span>
        </card>
        <card>
          <img class="h-16" src="../assets/azure.png" />
          <span class="text-center">Azure</span>
        </card>
      </section>
    </div>
  </main>
</template>

<script>
import Card from '../components/Card.vue';

export default {
  name: 'Technologies',
  components: {
    Card,
  },
};
</script>

<style>

</style>